<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import orderReservationsService from '../services/orderReservations.js'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            orderReservation:null,
            orderReservationId:this.$route.params.ori ? this.$route.params.ori : null,
            mangoDepositId: null,
            isLoading:false,
            captureBtnState:true,
            cancelBtnState:true,
            amountToCapture:null,
            reclaimReason:''
        }
    },
    methods: {

        async getOrderReservationById() {

            let response = await orderReservationsService.getOrderReservationById(this.orderReservationId)
            this.orderReservation = response.data
            this.amountToCapture = this.orderReservation.amount /100

            if (this.orderReservation.type == 'mangopay') {

                this.mangoDepositId = this.orderReservation.mango_deposit_id
            }
        },
        expireDisplay(daysExp) {

            if (daysExp <= 0) {

                return 'Expired ' + '('+(-daysExp) +' jours)'
            }
            return daysExp
        },
        async cancelPreAuth() {
            try {
                
                this.isLoading = true
                let response = await orderReservationsService.cancelPreAuth(this.mangoDepositId)

                if (response.data) {

                    this.getOrderReservationById()

                    this.cancelBtnState = true
                    this.amountToCapture = null
                    this.reclaimReason = ''
                }
            }
            catch(e) {
                console.log(e);
            }
            this.isLoading = false
        },
        async capturePreAuth() {
            try {
                
                this.isLoading = true
                let response = await orderReservationsService.capturePreAuth(this.amountToCapture * 100, this.orderReservation.mango_deposit_id, this.reclaimReason, this.orderReservation.order_id, this.orderReservation.id)
                if (response.data) {

                    this.getOrderReservationById()
                    
                    this.captureBtnState = true
                    this.amountToCapture = null
                    this.reclaimReason = ''
                }
            }
            catch(e) {
                    console.log(e);
            }
            this.isLoading = false
        }
    },
    created() {

        this.getOrderReservationById()
    },
    computed: {
        canCapture() {

            return  this.amountToCapture <= (this.orderReservation.amount /100) && this.reclaimReason.length > 10 
        },
        isOrderFinalized() {

            if (this.orderReservation == undefined) return false

            return ['EXPIRED', 'DONE'].includes(this.orderReservation.order_status)
        }
    }
}
</script>

<template>
    <dashboard-layout>
        <div v-if="orderReservation">
            <h2>{{orderReservation.reference}}</h2>
            <table class="table table-bordered col-md-6">
                <tbody>
                    <tr v-if="orderReservation.mango_deposit_id">
                        <th>Actions</th>
                        <td class="align-middle">
                            <div v-if="captureBtnState && cancelBtnState" :class="{'justify-content-end' : !captureBtnState, 'justify-content-around': captureBtnState}" class="d-flex flex-column">
                                <button :disabled="orderReservation.status != 'ACCEPTED' && orderReservation.status != 'PAYIN_FAILED'" @click="cancelBtnState = !cancelBtnState" class="btn btn-info mb-3">Cancel deposit ...</button>
                                <button :class="{'text-right' : !captureBtnState}" :disabled="orderReservation.status != 'ACCEPTED' && orderReservation.status != 'PAYIN_FAILED' " @click="captureBtnState = !captureBtnState" class="btn btn-danger">
                                    <span>Capture deposit ...</span>
                                </button>
                            </div>
                            <div class="d-flex flex-column justify-content-center w-100" v-if="!captureBtnState">
                                <input class="form-control text-right" v-model="amountToCapture" type="number" min="0" />
                                <textarea class="form-control mt-2" v-model="reclaimReason" placeholder="Reclaim reason" style="height: 150px;"></textarea>
                                <div class="d-flex flex-column justify-content-between">
                                    <button  :disabled="!canCapture || isLoading" @click="capturePreAuth" class="btn btn-success mt-2">
                                        Capture
                                        <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                    </button>
                                    <button :disabled="isLoading" @click="captureBtnState = !captureBtnState" class="btn cancel-action-button  mt-2">
                                        Return
                                    </button>
                                </div>
                                <p v-if="!canCapture" class="text-danger">This amount cannot be greater than the deposit amount, or the text is too short</p>
                            </div>
                            <div class="d-flex flex-column justify-content-center w-100" v-if="!cancelBtnState">
                                <p>
                                    Do you want to cancel this reservation ?
                                    <strong v-if="!isOrderFinalized" class="text-danger"><br>Warning ! Order is not finalized.</strong>
                                </p>
                                <div class="d-flex flex-column justify-content-between">
                                    <button :disabled="isLoading" @click=cancelPreAuth class="btn btn-success mt-2">
                                        Validate cancel
                                        <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                    </button>
                                    <button :disabled="isLoading" @click="cancelBtnState = !cancelBtnState" class="btn cancel-action-button mt-2">
                                        Return
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>ID</th>
                        <td class="align-middle">{{orderReservation.id}}</td>
                    </tr>
                    <tr>
                        <th>Order Public ID / status</th>
                        <td class="align-middle">{{orderReservation.reference}} / {{ orderReservation.order_status}}</td>
                    </tr>
                    <tr>
                        <th>Created At <small>(YYYY-MM-DD)</small></th>
                        <td class="align-middle">{{orderReservation.created_at}}</td>
                    </tr>
                    <tr>
                        <th>Amount</th>
                        <td class="align-middle">{{orderReservation.amount/ 100 }} €</td>
                    </tr>
                    <tr>
                        <th>Debited Amount</th>
                        <td class="align-middle">{{orderReservation.debited_amount / 100 }} €</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td class="align-middle">{{orderReservation.status}}</td>
                    </tr>
                    <tr>
                        <th>End Date <small>(YYYY-MM-DD)</small></th>
                        <td class="align-middle">{{orderReservation.end_date}}</td>
                    </tr>
                    <tr>
                        <th>Day before expiration</th>
                        <td class="text-success align-middle" :class="{'text-danger' : orderReservation.daysBeforeExp < 0}">{{expireDisplay(orderReservation.daysBeforeExp)}}</td>
                    </tr>
                    <tr>
                        <th>Type</th>
                        <td class="align-middle">{{orderReservation.type}}</td>
                    </tr>
                    <tr v-if="orderReservation.swikly_id">
                        <th>Swikly ID</th>
                        <td class="align-middle">{{orderReservation.swikly_id}}</td>
                    </tr>
                    <tr v-if="orderReservation.mango_deposit_id">
                        <th>Mango Deposit ID</th>
                        <td class="align-middle">{{orderReservation.mango_deposit_id}}</td>
                    </tr>

                </tbody>
            </table>
        </div>
    </dashboard-layout>
</template>

<style scoped>

.cancel-action-button {
    background-color: #dedede;
    color: black;
}

.cancel-action-button:hover {
    background-color: #c1c1c1;
}
</style>
